/**
 * day save cookie for CityAds - 30 days
 */
let channelLabel = 'utm_source';
const getSourceParamFromUri = function (channelLabel) {
  var pattern = channelLabel + '=([^&]+)';
  var re = new RegExp(pattern);
  return (re.exec(document.location.search) || [])[1] || '';
};
const getSourceCookie = function (cookieName) {
  var matches = document.cookie.match(new RegExp(
    '(?:^|; )' + cookieName.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)'
  ));
  return matches ? decodeURIComponent(matches[1]) : undefined;
};
function setCookieUtm(days) {
  let getParams = getSourceParamFromUri(channelLabel);
  if (!getParams) return;
  let paramsUrl = new URLSearchParams(window.location.search);

  paramsUrl.forEach((param, key) => {
    let period = days * 60 * 60 * 24 * 1000;
    let expiresDate = new Date((period) + +new Date);
    let cookieString = key + '=' + param + '; path=/; expires=' + expiresDate.toGMTString();
    document.cookie = cookieString + '; domain=loft-concept.ru';
  });

}
if (getSourceParamFromUri(channelLabel) != getSourceCookie(channelLabel)) {
  setCookieUtm(30);
}
